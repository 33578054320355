import styled from '@emotion/styled'
import React, { memo } from 'react'

export interface Props {
  description?: string
  title?: string
}

export const Paragraph = memo(function Paragraph({
  description,
  title,
}: Props) {
  return (
    <Container>
      {title ? <Title>{title}</Title> : null}
      {description ? (
        <Description dangerouslySetInnerHTML={{ __html: description }} />
      ) : null}
    </Container>
  )
})

const Container = styled.section`
  padding: 13.875rem 10vw 9rem;

  @media (max-width: 1199px) {
    padding-right: 4.861vw;
    padding-left: 4.861vw;
  }

  @media (max-width: 1023px) {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }

  @media (max-width: 767px) {
    padding-top: 9.5rem;
    padding-bottom: 5.625rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.75rem;
  font-weight: 600;
  line-height: 60px;
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.625rem;
  margin-top: 1.75rem;
  ul {
    list-style: initial;
    margin: 1.25rem 1rem 0;
  }
  a {
    color: ${({ theme }) => theme.colors.variants.primaryLight1};
  }
  p {
    margin: inherit;
  }
  b,
  strong {
    color: ${({ theme }) => theme.colors.variants.neutralDark1};
  }
  table {
    width: 100% !important;
    margin-top: 2.25rem;
    td {
      border: 1px solid ${({ theme }) => theme.colors.variants.neutralLight2};
      padding: 0.75rem;
    }
  }
`
